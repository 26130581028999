<template>
    <section>
        <div class="row">
            <div class="col-xxl-12 col-xl-12 col-lg-12
                        col-md-12 col-sm-12 col-12 d-flex mb-3">
                <div class="table-box p-3">
                    <div class="table-title d-flex flex-xxl-row flex-xl-row
                        flex-lg-row flex-md-row flex-sm-column flex-column mb-3">
                        <div class="flex-grow-1 my-auto">
                            <h2 class="mb-xxl-0 mb-xl-0 mb-lg-0
                            mb-md-0 mb-sm-3 mb-3 border-bottom pb-2">
                                {{ id ? 'Update Document' : 'Create New Document' }}
                            </h2>
                        </div>
                    </div>
                    <div class="new-template-form">
                        <Form @submit="submitForm"
                              :validation-schema="dataList.schema"
                              v-slot="{ errors }"
                              class="row">
                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 mb-3">
                                <label class="form-control-label">Title</label>
                                <Field
                                    type="text"
                                    name="title"
                                    v-model="dataList.formData.title"
                                    class="form-control form-control-sm"
                                    placeholder="Enter Title"
                                />
                                <span class="text-danger">
                                  {{ errors.title || getFormErrors.title }}
                                </span>
                            </div>

                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 mb-3">
                                <label class="form-control-label">Effective Date</label>
                                <Field
                                    type="date"
                                    name="effective_date"
                                    v-model="dataList.formData.effective_date"
                                    class="form-control form-control-sm"
                                    placeholder="Enter Title"
                                />
                                <span class="text-danger">
                                  {{ errors.effective_date || getFormErrors.effective_date }}
                                </span>
                            </div>

                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 mb-3">
                                <label class="form-control-label">Description</label>
                                <editor
                                    api-key="fz7abntsqhwkempr93qb1r1kpadfxgsv90laxsf7hgasjq3v"
                                    :disabled="disabled"
                                    id="editor_def_content"
                                    :init="{
                                        element_format: 'html',
                                        pthManager: siteUrl(`tinymce?company_id=${selectedCompany.id}`),
                                        menubar: 'insert',
                                        paste_as_text: true,
                                        branding: false,
                                        convert_urls: false,
                                        relative_urls: false,
                                        image_caption: true,
                                        image_advtab: true,
                                        height: 285,
                                        valid_elements: '+*[*]',
                                        pagebreak_separator: '<br style=\'page-break-before:always\'/>',
                                        nonbreaking_force_tab: true,
                                        content_css: ['/css/paper/paper.min.css?v=' + Math.random()],
                                        body_id: 'wrapper',
                                        plugins: [
                                            'autoresize advlist autolink lists link image charmap print preview anchor',
                                            'searchreplace visualblocks code fullscreen pagebreak',
                                            'insertdatetime media table paste code codesample help wordcount preview hr',
                                        ],
                                        external_plugins: {
                                            WYImageManager: '/tinymce/plugins/wy_image_manager/plugin.js',
                                        },
                                        toolbar: 'undo redo | formatselect | bold italic backcolor | \ alignleft aligncenter alignright alignjustify | WYImageManager | \ bullist numlist outdent indent | link s | table | glossary | removeformat | pagebreak | help',
                                    }"
                                    v-model="dataList.formData.description"/>

                            </div>
                            <div :class="['col-12 col-sm-12 mb-3', id ? 'col-md-6 col-lg-6' : 'col-md-12 col-lg-12']">
                                <label class="form-control-label">Location</label>
                                <Field as="textarea"
                                       name="location"
                                       v-model="dataList.formData.location"
                                       class="form-control form-control-sm"
                                       placeholder="Enter Location"
                                       rows="5"
                                />
                                <span class="text-danger">
                                  {{ errors.location || getFormErrors.location }}
                                </span>
                            </div>
                            <div v-if="id"
                                 class="col-12 col-sm-12 mb-3 col-md-6 col-lg-6">
                                <label class="form-control-label">Status</label>
                                <Field class="form-control form-control-sm"
                                       name="category_id"
                                       as="select"
                                       v-model="dataList.formData.status">
                                    <option value="" disabled>Select Status</option>
                                    <option v-for="(item, index) in dataList.statusList"
                                            :key="`status-${index}`"
                                            :value="item">
                                        {{ item }}
                                    </option>
                                </Field>
                                <span class="text-danger">
                                  {{ errors.location || getFormErrors.location }}
                                </span>
                            </div>
                            <div class="form-group row m-t-md">
                                <div class="col-sm-offset-2 col-sm-10">
                                    <button type="submit"
                                            :disabled="!data.permission.canEdit"
                                            class="btn btn-green me-2">
                                        {{ id ? 'Update' : 'Save' }}
                                    </button>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import {reactive, watch} from "vue";
import {useStore} from "vuex";
import HelperFunction from "@/common/helpers";
import {useRouter} from "vue-router";
import {Field, Form} from "vee-validate";

export default {
    name: "CreateExternalDocument",
    props: {
        id: {
            type: [Number, String],
            required: false,
        },
    },
    components: {Form, Field},
    setup(props) {
        const router = useRouter();
        const store = useStore();
        const {
            data,
            formPermissionResolver,
            getFormErrors,
            selectedCompany,
            storeFormData,
            getAllData
        } = HelperFunction();
        formPermissionResolver('/library/external-documents');
        let dataList = reactive({
            schema: null,
            formData: {
                title: '',
                effective_date: '',
                description: '',
                location: '',
                status: ''
            },
            statusList: ['Created', 'Completed']
        });

        /** watch **/
        watch(
            () => props.id,
            (value) => {
                if (value) {
                    getAllData(`${vueConfig.Library.ExternalDocumentsEndPoint}/${value}`)
                        .then(response => {
                            let data = response.data.data;
                            if (data) dataList.formData = {...dataList.formData, ...data};
                        })
                }
            },
            {immediate: true}
        );

        /** watch end**/
        function submitForm() {
            if (props.id) {
                updateForm();
            } else storeForm()
        }

        function storeForm() {
            storeFormData({url: vueConfig.Library.ExternalDocumentsEndPoint}, dataList.formData)
                .then(response => {
                    router.push('/library/external-documents');
                })
        }

        function updateForm() {
            let payload = {
                action: props.id,
                formData: dataList.formData
            }
            store.dispatch('updateExternalDocument', payload)
                .then(res => {
                    router.push('/library/external-documents');
                })
        }

        const siteUrl = (url) => {
            return process.env.VUE_APP_BACKEND_URL + url;
        };
        return {
            dataList,
            data,
            getFormErrors,
            submitForm,
            selectedCompany,
            siteUrl,
        };
    },
};
</script>


